<template>
  <div class="main" v-loading="loading">
    <div class="top">
      <div
        style="display: flex;align-items: center;justify-content: space-between;"
      >
        <p>公司经营数据</p>
        <div style="display: flex;align-items: center">
          <el-select
            size="small"
            v-model="listQuery.userId"
            placeholder="选择人员"
            clearable
            style="width:120px"
            @change="getList"
          >
            <el-option
              v-for="item in userOptions"
              :key="item.userid"
              :label="item.realName"
              :value="item.userid"
            ></el-option>
          </el-select>
          <el-date-picker
            v-model="listQuery.date"
            type="daterange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :disabled-date="disabledDate"
            size="small"
            style="width:200px"
            @change="getList"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD HH:mm:ss"
          >
          </el-date-picker>
        </div>
      </div>
      <el-row :gutter="20">
        <el-col :xs="6" :sm="6" :lg="6">
          <div class="each_info">
            <div class="info_title">咨询对话数</div>
            <div class="info_count">
              <span>{{ chatCnt }}</span
              >&nbsp;次
            </div>
          </div></el-col
        >
        <el-col :xs="6" :sm="6" :lg="6">
          <div class="each_info">
            <div class="info_title">回复对话数</div>
            <div class="info_count">
              <span>{{ replySessionCnt }}</span
              >&nbsp;次
            </div>
          </div>
        </el-col>
        <el-col :xs="6" :sm="6" :lg="6">
          <div class="each_info">
            <div class="info_title">发送消息数</div>
            <div class="info_count">
              <span>{{ messageCnt }}</span
              >&nbsp;条
            </div>
          </div></el-col
        >
        <el-col :xs="6" :sm="6" :lg="6">
          <div class="each_info">
            <div class="info_title">平均首次回复时长</div>
            <div class="info_count">
              <span>{{ avgReplyTime }}</span
              >&nbsp;分钟
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 10px;">
      <el-row :gutter="20">
        <el-col :xs="12" :sm="12" :lg="12">
          <el-row :gutter="20">
            <el-col :xs="11" :sm="11" :lg="11">
              <div class="pie">
                <p>及时回复占比</p>
                <div class="pie_info">
                  <el-progress
                    type="circle"
                    :percentage="ontimePctg"
                    :stroke-width="14"
                    color="#23D0C4"
                  >
                    <template #default="{ percentage }">
                      <div class="percentage-label">及时回复</div>
                      <div class="percentage-value">{{ percentage }}%</div>
                    </template></el-progress
                  >
                </div>
              </div>
            </el-col>
            <el-col :xs="13" :sm="13" :lg="13">
              <div class="pie">
                <p>活跃客户群占比</p>
                <div class="pie_info">
                  <el-progress
                    type="circle"
                    :percentage="activeGroupPctg"
                    :stroke-width="14"
                    color="#23D0C4"
                  >
                    <template #default="{ percentage }">
                      <div class="percentage-label">活跃客户群</div>
                      <div class="percentage-value">{{ percentage }}%</div>
                    </template></el-progress
                  >
                </div>
              </div></el-col
            >
          </el-row>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :lg="24">
              <div :style="{ height: contentStyleObj1 }" class="line">
                <div class="line_select">
                  <el-radio-group
                    v-model="type"
                    size="small"
                    @change="changeType"
                  >
                    <el-radio-button :label="1">发起申请数</el-radio-button>
                    <el-radio-button :label="2">新增客户</el-radio-button>
                    <el-radio-button :label="3">流失客户</el-radio-button>
                    <el-radio-button :label="4">群聊总数</el-radio-button>
                    <el-radio-button :label="5">新增群聊数</el-radio-button>
                    <el-radio-button :label="6">日活跃群</el-radio-button>
                    <el-radio-button :label="7">群发消息数</el-radio-button>
                  </el-radio-group>
                </div>
                <customerChart ref="customerChart" />
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="12" :sm="12" :lg="12">
          <div :style="{ height: contentStyleObj }" class="total_list">
            <div
              style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 5px;"
            >
              <p style="font-size: 12px;color: var(--themeColor,#17a2b8);">
                *仅展示激活互通账号的人员
              </p>
              <div>
                <!-- <el-date-picker
                            v-model="listQuery1.date"
                            type="daterange"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :disabled-date="disabledDate"
                            size="small"
                            style="width:200px"
                            @change="getUserData"
                            format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD HH:mm:ss"
                          >
                      </el-date-picker> -->
                <el-radio-group
                  v-model="listQuery1.timeMark"
                  size="small"
                  @change="getUserData"
                >
                  <el-radio-button :label="1">昨日</el-radio-button>
                  <el-radio-button :label="2">近7天</el-radio-button>
                  <el-radio-button :label="3">近30天</el-radio-button>
                </el-radio-group>
              </div>
            </div>
            <table cellspacing="0" style="width:100%" class="content">
              <tr class="top_border">
                <td class="center weight" style="width:10%;">序号</td>
                <td class="center weight" style="width:20%;">姓名</td>
                <td class="center weight" style="width:25%;">
                  平均首次回复时长
                </td>
                <td class="center weight" style="width:25%;">新增客户数</td>
                <td class="center weight" style="width:20%">客户群数</td>
              </tr>
            </table>

            <el-scrollbar :style="{ height: contentStyleObj2 }">
              <table
                cellspacing="0"
                style="width:100%;margin-top: 10px;"
                class="nonborder_content"
                v-if="list.length != 0"
              >
                <tr v-for="(item, index) in list">
                  <td class="center weight" style="width:10%;">
                    {{ index + 1 }}
                  </td>
                  <td class="center weight" style="width:20%;">
                    {{ item.userName }}
                  </td>
                  <td class="center weight" style="width:25%;">
                    {{ item.avgReplyTime }}
                  </td>
                  <td class="center weight" style="width:25%;">
                    {{ item.newCustomerCnt }}
                  </td>
                  <td class="center weight" style="width:20%">
                    {{ item.groupCnt }}
                  </td>
                </tr>
              </table>
              <el-empty description="暂无数据" v-else></el-empty>
            </el-scrollbar>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getUserBehaviorData, getCorpUser, getUserListData } from "@/api/qywx";
import customerChart from "../components/customerChart.vue";
export default {
  name: "",
  components: {
    customerChart,
  },
  props: {},
  data() {
    return {
      disabledDate(time) {
        return time.getTime() > Date.now() - 1 * 24 * 3600 * 1000;
      },
      contentStyleObj: {},
      contentStyleObj1: {},
      contentStyleObj2: {},
      type: 1,
      year: "2023",
      list: [],
      activeGroupPctg: 0,
      avgReplyTime: 0,
      chatCnt: 0,
      messageCnt: 0,
      ontimePctg: 0,
      replySessionCnt: 0,
      listQuery: {
        startTime: "",
        endTime: "",
        timeMark: 3,
        userId: "",
        userIds: [],
        date: [],
      },
      listQuery1: {
        startTime: "",
        endTime: "",
        timeMark: 3,
        date: [],
      },
      userOptions: [],
      addCustomerPanelData: {}, //新增客户
      customerChurnPanelData: {}, //流失客户
      dayActiveGroupPanelData: {}, //日活跃群
      groupTotalPanelData: {}, //群聊总数
      msgTotalPanelData: {}, //群聊消息数
      newApplyCntPanelData: {}, //发起申请数
      newAddGroupCntPanelData: {}, ///新增客户群数
      loading: false,
      defaultInfo:{
        x:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
        y:[0,0,0,0,0,0,0,0,0,0,0,0]
      }
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(275);
    this.contentStyleObj1 = this.$getHeight(455);
    this.contentStyleObj2 = this.$getHeight(380);
    this.getList();
    this.getUser();
    this.getUserData();
  },
  methods: {
    getUser() {
      getCorpUser({}).then((res) => {
        if (res.data.msg == "success") {
          this.userOptions = res.data.data;
        }
      });
    },
    getUserData() {
      this.loading = true;
      if (this.listQuery1.date.length != 0) {
        this.listQuery1.timeMark = 0;
        this.listQuery1.startTime = this.listQuery1.date[0];
        this.listQuery1.endTime = this.listQuery1.date[1];
      }
      getUserListData(this.listQuery1).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data.userDataList;
        }
      });
    },
    getList() {
      this.loading = true;
      if (this.listQuery.userId) {
        this.listQuery.userIds = [this.listQuery.userId];
      } else {
        this.listQuery.userIds = [];
      }
      if (this.listQuery.date.length != 0) {
        this.listQuery.timeMark = 0;
        this.listQuery.startTime = this.listQuery.date[0];
        this.listQuery.endTime = this.listQuery.date[1];
      }
      getUserBehaviorData(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          let data = res.data.data;
          this.activeGroupPctg = data.activeGroupPctg;
          this.avgReplyTime = data.avgReplyTime;
          this.chatCnt = data.chatCnt;
          this.messageCnt = data.messageCnt;
          this.ontimePctg = data.ontimePctg;
          this.replySessionCnt = data.replySessionCnt;
          this.addCustomerPanelData = data.addCustomerPanelData;
          this.customerChurnPanelData = data.customerChurnPanelData;
          this.dayActiveGroupPanelData = data.dayActiveGroupPanelData;
          this.groupTotalPanelData = data.groupTotalPanelData;
          this.msgTotalPanelData = data.msgTotalPanelData;
          this.newApplyCntPanelData = data.newApplyCntPanelData;
          this.newAddGroupCntPanelData = data.newAddGroupCntPanelData;
          this.changeType();
        }else{
          this.$refs.customerChart.init(this.defaultInfo)
        }
      });
    },
    changeType() {
      if (this.type == 1) {
        this.$refs.customerChart.init(this.newApplyCntPanelData);
      } else if (this.type == 2) {
        this.$refs.customerChart.init(this.addCustomerPanelData);
      } else if (this.type == 3) {
        this.$refs.customerChart.init(this.customerChurnPanelData);
      } else if (this.type == 4) {
        this.$refs.customerChart.init(this.groupTotalPanelData);
      } else if (this.type == 5) {
        this.$refs.customerChart.init(this.newAddGroupCntPanelData);
      } else if (this.type == 6) {
        this.$refs.customerChart.init(this.dayActiveGroupPanelData);
      } else if (this.type == 7) {
        this.$refs.customerChart.init(this.msgTotalPanelData);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 98%;
  margin: 0 auto;
  padding-top: 1%;
}
.top {
  background-color: var(--themeColorLeftMenu,#ecf7f9);
  padding: 10px 10px 30px;
  border-radius: 4px;
  p {
    font-size: 14px;
    color: var(--themeColor, #17a2b8);
  }
  .each_info {
    padding-left: 20px;
    margin-top: 15px;
    .info_title {
      padding-bottom: 5px;
      font-size: 13px;
    }
    .info_count {
      text-indent: 3em;
      font-size: 13px;
      span {
        font-size: 24px;
      }
    }
  }
}
.pie {
  background-color: var(--themeColorLeftMenu,#ecf7f9);
  border-radius: 4px;
  height: 170px;
  padding: 10px 20px;
  p {
    font-size: 14px;
    color: var(--themeColor, #17a2b8);
  }
  .pie_info {
    text-align: center;
  }
}
.line {
  margin-top: 10px;
  background-color: var(--themeColorLeftMenu,#ecf7f9);
  border-radius: 4px;
  padding: 10px 20px;
  .line_select {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.total_list {
  background-color: var(--themeColorLeftMenu,#ecf7f9);
  border-radius: 4px;
  padding: 10px 20px;
}

.content {
  border: 1px solid var(--themeColor, #17a2b8);
  border-right: none;
  border-radius: 3px;
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid var(--themeColor, #17a2b8);
    line-height: 26px;
    padding: 0 6px;
    color: #333;
    font-size: 14px;
  }
  .center {
    text-align: center;
    font-size: 14px;
    color: #333;
  }
}
.nonborder_content {
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-bottom: 1px solid var(--themeColor, #17a2b8);
    line-height: 30px;
    padding: 5px 6px;
    color: #333;
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .center {
    text-align: center;
    font-size: 13px;
    color: #333;
  }
}
</style>
