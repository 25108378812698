<template>
  <div
    id="myChart"
    style="width: 100%;height:330px;"
    :style="{ height: contentStyleObj }"
  ></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "receiveEchart",
  components: {},
  props: {},
  data() {
    return {
      contentStyleObj: {},
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(474);
  },
  mounted() {
    //this.initChart();
  },
  methods: {
    init(list) {
      this.$nextTick(() => {
        this.initChart(list);
      });
    },
    initChart(info) {
      var myChart = echarts.init(document.getElementById("myChart"));
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "rgba(50, 216, 205, 1)",
            },
          },
        },
        grid: {
          left: "5%",
          right: "10%",
          top: "20%",
          bottom: "15%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: 1,
            axisLine: {
              show: false,
            },
            axisLabel: {
              color: "#A1A7B3",
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },

            data: info.x
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            padding: 5,
            // max: 1000,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#A1A7B3",
                type: "dashed",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              margin: 10,
              textStyle: {
                color: "#A1A7B3",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "数量",
            type: "line",
            smooth: true,
            stack: "总量",
            symbolSize: 5,
            showSymbol: false,
            itemStyle: {
              normal: {
                color: "#23D0C4",
                lineStyle: {
                  color: "#23D0C4",
                  width: 2,
                },
              },
            },
            markLine:{
                data:[
                    {
                        type:'average',
                        name:"平均值"
                    }
                ]
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(50, 216, 205, 1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(255, 255, 255, 0.2)",
                    },
                  ],
                  false
                ),
              },
            },
            data: info.y,
          },
        ],
      });
      window.onresize = function() {
        myChart.resize();
      };
    },
  },
};
</script>

<style scoped lang="scss"></style>
